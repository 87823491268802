sortamountupalticon,
angledoublelefticon,
angledoublerighticon,
anglelefticon,
anglerighticon,
calendaricon,
checkicon,
chevrondownicon,
chevronlefticon,
chevronrighticon,
searchicon,
timesicon,
timescircleicon,
blankicon {
  svg {
    display: none !important;
  }
  &::before {
    font-family: 'Material Icons';
    font-weight: 400;
    font-size: 24px;
  }
}

blankicon::before {
  content: 'mood';
  color: transparent;
}

chevrondownicon::before {
  content: 'expand_more';
}

chevronrighticon::before {
  content: 'chevron_right';
}

chevronlefticon::before {
  content: 'chevron_left';
}

searchicon::before {
  content: 'search';
}

checkicon::before {
  content: 'check';
}

calendaricon::before {
  content: 'event';
}

angledoublelefticon::before {
  content: 'keyboard_double_arrow_left';
}

anglelefticon::before {
  content: 'chevron_left';
}

angledoublerighticon::before {
  content: 'keyboard_double_arrow_right';
}

anglerighticon::before {
  content: 'chevron_right';
}

timesicon::before {
  content: 'close';
}

timescircleicon::before {
  content: 'close';
}

sortamountupalticon,
sortamountdownicon,
sortalticon {
  svg.p-icon {
    display: none !important;
  }
  &::before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
  }
}

sortamountupalticon::before {
  background-image: url("data:image/svg+xml,%3Csvg width='12px' height='18px' viewBox='0 0 12 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eactions / sort_upwards%3C/title%3E%3Cg id='Module' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Product-page_APPLICATIONS' transform='translate(-1350.000000, -430.000000)'%3E%3Cg id='actions-/-sort_upwards' transform='translate(1344.000000, 427.000000)'%3E%3Cpolygon id='Shape' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpolygon id='sort_upwards' fill='%23737F85' transform='translate(12.000000, 12.000000) rotate(-360.000000) translate(-12.000000, -12.000000) ' points='6 9.62035395 7.02080951 10.5277914 11.3126 5.79295121 11.3126 21 12.6846558 21 12.6846558 5.80585638 16.9737023 10.5841666 18 9.6808045 12.000686 3'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

sortamountdownicon::before {
  background-image: url("data:image/svg+xml,%3Csvg width='12px' height='18px' viewBox='0 0 12 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eactions / sort_downwards%3C/title%3E%3Cg id='Module' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Product-page_APPLICATIONS' transform='translate(-1260.000000, -426.000000)'%3E%3Cg id='actions-/-sort_downwards' transform='translate(1254.000000, 423.000000)'%3E%3Cpolygon id='Shape' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpolygon id='sort_downwards' fill='%23737F85' transform='translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) ' points='6 9.62035395 7.02080951 10.5277914 11.3126 5.79295121 11.3126 21 12.6846558 21 12.6846558 5.80585638 16.9737023 10.5841666 18 9.6808045 12.000686 3'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

sortalticon::before {
  background-image: url("data:image/svg+xml,%3Csvg width='18px' height='18px' viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eactions / sort_default%3C/title%3E%3Cg id='Module' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Product-page_APPLICATIONS' transform='translate(-1137.000000, -423.000000)'%3E%3Cg id='actions-/-sort_default' transform='translate(1134.000000, 420.000000)'%3E%3Cpolygon id='Shape' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M7.5110652,3 L12,8 L10.9760698,8.90336214 L8.19345721,5.80585638 L8.19345721,21 L6.82456652,21 L6.82456652,5.79295121 L4.01845467,9 L3,8.09256254 L7.5110652,3 Z M17.1934572,3 L17.1934572,18.1941436 L19.9760698,15.0966379 L21,16 L16.5110652,21 L12,15.9074375 L13.0184547,15 L15.8245665,18.2070488 L15.8245665,3 L17.1934572,3 Z' id='sort_default' fill='%23737F85' transform='translate(12.000000, 12.000000) rotate(-360.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
