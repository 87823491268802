@use 'synergy/colors' as colors;

p-selectButton {
  &.p-selectbutton {
    display: flex;
    button.p-togglebutton {
      display: flex;
      color: colors.$white;
      background: colors.$primary;
      padding: 0;
      height: 50px;
      width: 50px;
      align-items: center;
      &.p-togglebutton-checked {
        background: colors.$primary-dark;
      }
      > * {
        width: 100%;
        height: 100%;
      }
      i.pi {
        position: relative;
        top: 25%;
      }
    }
  }
}
