@use 'synergy/colors' as colors;

.p-skeleton {
  background-color: colors.$midgray-3;
  border-radius: 3px;
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(colors.$white, 0),
    rgba(colors.$white, 0.4),
    rgba(colors.$white, 0)
  );
}
