@use 'synergy/colors' as colors;

$spinner-svg-start: 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" stroke-miterlimit="10" fill="none"';
$spinner-svg-end: 'stroke="%23c5cacc"></circle></svg>';

// The attr() function does work for URLs. So the stroke width is limited to 1, 2 (default) and 4.
p-progressspinner {
  .p-progressspinner {
    width: 100%;
    height: 100%;
    .p-progressspinner-spin {
      background-image: url('#{$spinner-svg-start} stroke-width="2" #{$spinner-svg-end}');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      animation: none;
      .p-progressspinner-circle {
        animation: sick-progress-spinner 1s linear infinite;
        stroke: colors.$primary;
        stroke-dasharray: 50%, 200%;
        stroke-dashoffset: 0;
        stroke-linecap: butt;
        stroke-width: 2;
      }
    }
  }
}

p-progressspinner[strokeWidth='1'] {
  .p-progressspinner .p-progressspinner-spin {
    background-image: url('#{$spinner-svg-start} stroke-width="1" #{$spinner-svg-end}');
    .p-progressspinner-circle {
      stroke-width: 1;
    }
  }
}

p-progressspinner[strokeWidth='4'] {
  .p-progressspinner .p-progressspinner-spin {
    background-image: url('#{$spinner-svg-start} stroke-width="4" #{$spinner-svg-end}');
    .p-progressspinner-circle {
      stroke-width: 4;
    }
  }
}

@keyframes sick-progress-spinner {
  0% {
    stroke-dashoffset: 0%;
  }
  100% {
    stroke-dashoffset: -250%;
  }
}
