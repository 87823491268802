@use 'sds/fonts' as *;
@use './_button.scss' as *;
@use 'synergy/colors' as colors;

p-autoComplete {
  .p-autocomplete {
    min-height: 46px;
    display: flex;
    align-items: center;
    border: 1px solid colors.$outline;
    background: colors.$background;
    padding: 0 16px;

    &:hover {
      border: 1px solid colors.$outline;
    }

    &.p-focus {
      border: 1px solid colors.$primary;
    }

    &.p-disabled {
      border-color: colors.$midgray-3;
    }

    .p-autocomplete-input-multiple {
      flex: 1 1 auto;
      display: flex;
      gap: 8px;
      padding: 7px 0;
      order: 0;
      border: none;
      height: auto;
      .p-autocomplete-input-chip {
        flex: 1 1 0;
      }
    }

    timesicon,
    .p-autocomplete-clear-icon {
      @include button-bare-neutral();
      order: 1;
      position: initial;
      margin-top: 0;
    }

    .p-autocomplete-input-chip {
      height: 32px;
      padding: 0;
    }

    .p-autocomplete-chip {
      border: 1px solid colors.$midgray-1;
      border-radius: 4px;
      display: flex;
      gap: 8px;
      height: 32px;
      padding: 0 8px 0 12px;
      background: transparent;
      margin: 0;

      .p-chip-label {
        color: colors.$text;
      }

      .p-chip-remove-icon {
        @include button-bare-neutral();
        width: auto;
        .p-autocomplete-chip-icon {
          display: flex;
        }
      }

      &.p-focus {
        border-color: colors.$primary;
        background: transparent;
        .p-chip-remove-icon {
          color: colors.$primary;
        }
      }
    }

    .p-autocomplete-input,
    .p-autocomplete-dd-input {
      height: 100%;
      border: none;
      padding: 0;
      text-indent: 0;
    }

    .p-autocomplete-dropdown {
      @include button-bare-neutral();
      height: 100%;
      order: 2;
      padding: 0;
      border: none;
      &.p-component:disabled {
        color: colors.$outline-light;
      }
    }

    .p-autocomplete-loader {
      // the default loader is not shown because of the characteristics of primeng that they don't expect the content loaded dynamically
      display: none;
      & ~ .p-autocomplete-dropdown {
        display: none;
      }
    }
  }
}

.p-autocomplete-overlay {
  background: colors.$background;
  box-shadow: 0 2px 6px 0 colors.$darkgray-1;
  outline: thin solid colors.$midgray-1;
  margin-top: 4px;
  text-align: left;
  border-radius: 0;

  .p-autocomplete-list {
    padding: 0px;
    gap: 0;
  }

  .p-autocomplete-item-group {
    padding: 8px 16px;
    color: colors.$text;
    font-weight: $font-semibold;
  }

  .p-autocomplete-option {
    margin: 0;
    padding: 8px 16px;
    color: colors.$text;
    white-space: normal;
    border-left: 6px solid transparent;

    &.p-autocomplete-option-selected {
      background-color: colors.$background-3;
      border-left: 6px solid colors.$primary-light;
      border-radius: 0;
    }
    &.p-focus {
      background-color: colors.$background-3;
    }
  }

  .p-autocomplete-empty-message {
    padding: 8px 16px;
  }
}
