@use 'synergy/colors' as colors;

p-toggleswitch {
  width: 30px;
  height: 18px;

  .p-toggleswitch {
    height: 100%;
    width: 100%;
    background: inherit;

    .p-toggleswitch-slider {
      background: colors.$darkgray-2;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s;
      border-radius: 30px;
    }

    .p-toggleswitch-slider .p-toggleswitch-handle {
      background: colors.$background;
      width: 14px;
      height: 14px;
      left: 3px;
      margin-top: -7px;
      border-radius: 50%;
      transition-duration: 0.2s;
    }

    &.p-toggleswitch-checked .p-toggleswitch-slider {
      background: colors.$primary;
    }

    &.p-toggleswitch-checked .p-toggleswitch-slider .p-toggleswitch-handle {
      transform: translateX(calc(100% - 3px));
    }

    &:not(.p-disabled):hover .p-toggleswitch-slider {
      background: colors.$darkgray-4;
    }

    &.p-toggleswitch-checked:not(.p-disabled):hover .p-toggleswitch-slider {
      background: colors.$primary-light;
    }

    &.p-focus .p-toggleswitch-slider {
      outline: 0 none;
    }

    &.p-disabled .p-toggleswitch-slider {
      background: colors.$midgray-2;
    }
  }

  &.ng-dirty.ng-invalid > .p-toggleswitch {
    border-color: colors.$error;
  }
}
