// Animation on page load...
#animation-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 127, 195); // var(--ion-color-primary);

  .animation {
    position: relative;
    text-align: center;
    color: white;
    > .sick-logo {
      width: 5vw;
      min-width: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: white;
    }

    > .spinner {
      min-width: 250px;
      min-height: 250px;
      position: relative;
      > .circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: white;
        opacity: 0.25;
        position: absolute;
        top: 0;
        left: 0;
      }
      > .circle.bounce {
        -webkit-animation: sk-bounce 2s infinite ease-in-out;
        animation: sk-bounce 2s infinite ease-in-out;
      }
      > .circle.delayed {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
    }
  }

  #bootstrap-message {
    text-align: center;
    max-width: 75%;
    margin-top: 50px;
    color: lightgray;
    font-size: 1.25rem;

    &.error {
      text-align: left;
    }
  }
}

.fadeout {
  opacity: 0;
  animation: bootstrap-fade 0.5s linear;
}

@keyframes bootstrap-fade {
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
