@use 'synergy/colors' as colors;
@use 'primeng/components/button' as *;

p-accordion {
  min-width: 15em;
  color: inherit;
  border-top: none;
  line-height: 24px;

  p-accordion-panel {
    width: 100%;
    border: none;
    p-accordion-header {
      border: none;
      padding: 12px 0 8px 0;
      background: #ffffff;
      border-bottom: 1px solid colors.$lightgray-1;
      justify-content: space-between;
      font-size: 16.8px;
      font-weight: 700;
      color: colors.$text;
      @include button-bare('.p-disabled', inherit, colors.$text);

      :not(.customIcon) {
        font-size: 16.8px;
        line-height: 1.4;
        font-weight: 700;
        color: colors.$text-light;
      }

      .customIcon {
        margin-right: 1%;
        font-size: 24px;
        color: colors.$midgray-2;
        order: 2;
        color: colors.$primary;
        font-weight: 400;
      }

      .customPlus:before {
        content: '+';
      }

      .customMinus:before {
        content: '\2212';
      }
    }
  }
}
p-accordion-content {
  .p-accordioncontent-content {
    margin: 12px 0 0 0;
    padding: 0 0 1em 0;
    border-bottom: 1px solid colors.$lightgray-1;
  }
}
