@use 'synergy/colors' as colors;

@mixin button-bare-neutral($disabled: ':disabled') {
  color: colors.$midgray-1;
  & > svg {
    fill: colors.$midgray-1;
  }
  background-color: transparent;
  &:not(#{$disabled}) {
    color: colors.$darkgray-2;
    & > svg {
      fill: colors.$darkgray-2;
    }
    background-color: transparent;
    &:focus:not(:active) {
      outline: 1px solid colors.$secondary-dark;
      outline-offset: -1px;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
    &:hover {
      color: colors.$darkgray-4;
      & > svg {
        fill: colors.$darkgray-4;
      }
      background-color: transparent;
    }
    &:active {
      color: colors.$darkgray-1;
      & > svg {
        fill: colors.$darkgray-1;
      }
      background-color: transparent;
    }
  }
}

@mixin button-bare($disabled: ':disabled', $bg: transparent, $fg: colors.$primary) {
  color: colors.$midgray-1;
  & > svg {
    fill: colors.$midgray-1;
  }
  background-color: $bg;
  &:not(#{$disabled}) {
    color: $fg;
    & > svg {
      fill: $fg;
    }
    background-color: $bg;
    &:focus:not(:active) {
      outline: 1px solid colors.$primary;
      outline-offset: -1px;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
    &:hover {
      color: colors.$primary-dark;
      & > svg {
        fill: colors.$primary-dark;
      }
      background-color: $bg;
    }
    &:active {
      color: colors.$primary-extra-dark;
      & > svg {
        fill: colors.$primary-extra-dark;
      }
      background-color: $bg;
    }
  }
}

@mixin button-outline($disabled: ':disabled') {
  color: colors.$midgray-2;
  & > svg {
    fill: colors.$midgray-2;
  }
  background-color: transparent;
  border: 1px solid colors.$midgray-2;
  &:not(#{$disabled}) {
    color: colors.$primary;
    & > svg {
      fill: colors.$primary;
    }
    background-color: transparent;
    border-color: colors.$primary;
    &:focus:not(:active) {
      outline: 1px solid colors.$primary;
      outline-offset: -1px;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
    &:hover {
      color: colors.$white;
      & > svg {
        fill: colors.$white;
      }
      background-color: colors.$primary-dark;
      border-color: colors.$primary-dark;
    }
    &:active {
      color: colors.$white;
      & > svg {
        fill: colors.$white;
      }
      background-color: colors.$primary-extra-dark;
      border-color: colors.$primary-extra-dark;
    }
  }
}

@mixin button-primary($disabled: ':disabled') {
  color: colors.$midgray-1;
  & > svg {
    fill: colors.$midgray-1;
  }
  background-color: colors.$midgray-3;
  &:not(#{$disabled}) {
    color: colors.$white;
    & > svg {
      fill: colors.$white;
    }
    background-color: colors.$primary;
    &:focus:not(:active) {
      outline: 1px solid colors.$primary;
      outline-offset: 2px;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
    &:hover {
      color: colors.$white;
      & > svg {
        fill: colors.$white;
      }
      background-color: colors.$primary-dark;
    }
    &:active {
      color: colors.$white;
      & > svg {
        fill: colors.$white;
      }
      background-color: colors.$primary-extra-dark;
    }
  }
}

.p-button {
  border: none;
  font-size: 1rem;
  font-weight: 600; // not as defined in SDS (see DAVIBE-606)
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 46px;
  height: 46px;
  padding: 0px 16px;
  justify-content: center;
  gap: 8px;
  border-radius: 0;
  & > * {
    // prevents drag-enter/leave from firing for child nodes in case
    // you want to install a drag/drop handler for a button
    pointer-events: none;
  }
  .p-button-label {
    flex: initial;
    font: inherit;
  }

  .p-button-icon.icon-label-button {
    font-size: 18px;
    margin-right: 3px;
  }

  &.p-button-icon-only {
    min-height: auto;
    width: min-content;
    // workaround b/c primeng fixed the &nbsp; in empty labels but came up with more
    // weird ways to break our layout
    &::after {
      display: none;
    }
  }

  &.slim {
    padding: 0;
    margin: 0;
    height: auto;
    min-height: auto;
  }

  @extend .ui-button-primary;

  &.ui-button-primary {
    color: colors.$midgray-1;
    background-color: colors.$midgray-3;
    &:enabled {
      color: colors.$white;
      background-color: colors.$primary;
      &:enabled:focus:not(:active) {
        box-shadow: none;
        outline: 1px solid colors.$primary;
        outline-offset: 2px;
      }
      &:enabled:focus:not(:focus-visible) {
        outline: none;
      }
      &:hover {
        background-color: colors.$primary-dark;
      }
      &:active {
        background-color: colors.$primary-extra-dark;
      }
    }
  }
  &.ui-button-neutral {
    color: colors.$midgray-1;
    background-color: colors.$midgray-3;
    &:enabled {
      color: colors.$white;
      background-color: colors.$darkgray-2;
      &:enabled:focus:not(:active) {
        box-shadow: none;
        outline: 1px solid colors.$darkgray-2;
        outline-offset: 2px;
      }
      &:enabled:focus:not(:focus-visible) {
        outline: none;
      }
      &:hover {
        background-color: colors.$darkgray-4;
      }
      &:active {
        background-color: colors.$darkgray-1;
      }
    }
  }
  &.ui-button-outline {
    color: colors.$midgray-2;
    background-color: transparent;
    border: 1px solid colors.$midgray-2;
    &:enabled {
      color: colors.$primary;
      background-color: transparent;
      border-color: colors.$primary;
      &:hover {
        color: colors.$primary-dark;
        border-color: colors.$primary-dark;
      }
      &:active {
        color: colors.$primary-extra-dark;
        border-color: colors.$primary-extra-dark;
      }
    }
  }
  &.ui-button-outline-neutral {
    color: colors.$midgray-2;
    background-color: transparent;
    border: 1px solid colors.$midgray-2;
    &:enabled {
      color: colors.$darkgray-2;
      background-color: transparent;
      border-color: colors.$darkgray-2;
      &:hover {
        color: colors.$white;
        background-color: colors.$darkgray-4;
        border-color: colors.$darkgray-4;
      }
      &:active {
        color: colors.$white;
        background-color: colors.$darkgray-1;
        border-color: colors.$darkgray-1;
      }
    }
  }
  &.ui-button-bare,
  &.p-datepicker-trigger {
    @include button-bare;
  }
  &.ui-button-bare-neutral {
    @include button-bare-neutral;
  }

  &.icon-button-small .p-button-icon.pi {
    font-size: 18px;
    color: colors.$darkgray-1;
  }
}
