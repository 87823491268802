@use 'tokens' as *;

// Primary Colors

$primary-extra-dark: $SynColorPrimary950;
$primary-dark: $SynColorPrimary900;
$primary-800: $SynColorPrimary800;
$primary-700: $SynColorPrimary700;
$primary: $SynColorPrimary600;
$primary-light: $SynColorPrimary500;
$primary-400: $SynColorPrimary400;
$primary-300: $SynColorPrimary300;
$primary-200: $SynColorPrimary200;
$primary-100: $SynColorPrimary100;
$primary-50: $SynColorPrimary50;

// Secondary Colors
$secondary: $SynColorNeutral700;
$secondary-light: $SynColorNeutral600;
$secondary-dark: $SynColorNeutral900;

// Accent Colors
$accent: $SynColorAccent500;
$accent-light: $SynColorAccent400;
$accent-dark: $SynColorAccent600;

// UI and neutral colors
$black: $SynColorNeutral1000;
$darkgray-1: $SynColorNeutral950;
$darkgray-2: $SynColorNeutral900;
$darkgray-3: $SynColorNeutral800;
$darkgray-4: $SynColorNeutral700;
$midgray-1: $SynColorNeutral600;
$midgray-2: $SynColorNeutral500;
$midgray-3: $SynColorNeutral400;
$lightgray-1: $SynColorNeutral300;
$lightgray-2: $SynColorNeutral200;
$lightgray-3: $SynColorNeutral100;
$lightgray-4: $SynColorNeutral50;
$white: $SynColorNeutral0;

// Text Colors
$text: $SynColorNeutral950;
$text-light: $SynColorNeutral900;
$text-lighter: $SynColorNeutral800;
$text-disabled: $SynColorNeutral400;
$textWhite: $SynColorNeutral0;

// UI and neutral colors
$input-border: $SynColorNeutral700;
$input-border-hover: $SynColorNeutral950;
$input-placeholder: $SynColorNeutral500;
$panel-border: $SynColorNeutral300;
$focus-ring: $SynColorPrimary400;

$outline: $SynColorNeutral600;
$outline-light: $SynColorNeutral400;
$border-disabled: $SynColorNeutral300;

$background: $SynColorNeutral0;
$background-3: $SynColorNeutral100;

// Tables
$table-content-color: $SynColorNeutral950;
$table-header-background: $SynColorNeutral200;
$table-row-even: $SynColorNeutral50;
$table-row-odd: $SynColorNeutral0;
$table-col-highlight: $SynColorNeutral700;

// Status colors
$info: $SynColorPrimary600;
$info-light: $SynColorPrimary200;
$success: $SynColorSuccess500;
$success-light: $SynColorSuccess100;
$warning: $SynColorWarning400;
$warning-light: $SynColorWarning100;
$error: $SynColorError600;
$error-light: $SynColorError100;
$neutral: $SynColorNeutral800;

// Custom - non official SICK color codes
$custom-light-blue: #c5e9fc;
// Filter/Sorter page
$search-value: #f4cccc;
$selected-trees: #d9ead3;
$selected-buildings-or-locations: #d4ebff;
$selected-types: #aff8e6;
$selected-vendors: #c8caf8;
$selected-product-names: #e2d1ea;
$selected-firmware-states: #f7dfa6;
