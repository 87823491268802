@use 'synergy/colors' as colors;

p-rating {
  .p-iconwrapper {
    color: colors.$darkgray-2;
    .p-rating-icon {
      width: 24px;
      height: 24px;
      color: inherit;
    }
  }
}
