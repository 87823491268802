@use 'synergy/colors' as colors;

p-radiobutton {
  line-height: 24px; // label stays centered if display=block

  .p-radiobutton {
    vertical-align: bottom;
    width: 24px;
    height: 24px;

    .p-radiobutton-box {
      background-color: colors.$background;
      border: 1px solid colors.$outline;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      text-align: center;
      position: relative;

      .p-radiobutton-icon {
        width: 14px;
        height: 14px;
        // required by ipad
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -7px;
        margin-top: -7px;
      }
    }

    &.p-disabled {
      .p-radiobutton-box {
        border-color: colors.$outline-light;
        .p-radiobutton-icon {
          background-color: colors.$outline-light;
        }
      }
    }

    &.p-radiobutton-checked:not(.p-disabled) .p-radiobutton-box .p-radiobutton-icon {
      background-color: colors.$primary;
    }

    .p-radiobutton-box.p-focus,
    &:hover .p-radiobutton-box:not(.p-disabled) {
      border-color: colors.$primary;
    }
  }
}
