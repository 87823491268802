@use 'synergy/colors' as colors;

@mixin checkbox-box {
  .p-checkbox {
    width: 24px;
    height: 24px;
    .p-checkbox-box {
      background-color: colors.$background;
      border: 1px solid colors.$outline;
      border-radius: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      checkicon {
        &::before {
          color: colors.$primary;
          font-size: 22px;
        }
      }
    }

    &:hover,
    &.p-checkbox-checked {
      .p-checkbox-box {
        border-color: colors.$primary;
      }
    }

    &.p-disabled {
      .p-checkbox-box {
        border-color: colors.$outline-light;
        checkicon::before {
          color: colors.$outline-light;
        }
      }
    }
  }
}

p-checkbox {
  height: 24px;
  margin: 13px 0px;
  @include checkbox-box;

  &.error {
    .p-checkbox .p-checkbox-box {
      border-color: colors.$error;
      checkicon::before {
        color: colors.$error;
      }
    }
  }
}
