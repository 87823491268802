@use 'synergy/colors' as colors;
@use 'button' as *;
@use 'primeng/components/button' as *;

p-breadcrumb {
  .p-breadcrumb {
    padding: 0;
    background: inherit;
    .p-breadcrumb-list {
      gap: 0;
    }
    .p-breadcrumb-item-label\', // bug in primeng
    .p-breadcrumb-item-label {
      font-size: 12px;
      padding-left: 2px;
      padding-right: 2px;
      // color: colors.$darkgray-4;
      cursor: default;
    }
    .p-breadcrumb-item-link {
      display: inline;
      @include button-bare('.p-disabled', inherit, colors.$darkgray-4);
    }
    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        pointer-events: none;
      }

      &:not(:last-child) {
        min-width: 1em;
        &:hover .p-breadcrumb-item-label {
          color: colors.$primary;
        }

        &:not(.pi-ellipsis-h) {
          .p-breadcrumb-item-label\',
          .p-breadcrumb-item-label {
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
    .p-breadcrumb-chevron {
      font-size: 18px;
      color: colors.$midgray-2;
    }
    .pi-ellipsis-h {
      font-size: 12px;
      line-height: inherit;
      color: colors.$darkgray-4;
    }
  }
}
