@use 'synergy/colors' as colors;

.p-slider {
  box-sizing: border-box;
  position: relative;
  max-width: 250px;
  width: 100%;
  height: 2px;
  background: colors.$midgray-3;
  border: 0 none;
  border-radius: 1px;
}
.p-slider .p-slider-range {
  background: colors.$primary;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  box-sizing: border-box;
}
.p-slider .p-slider-handle {
  height: 16px;
  width: 16px;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
  touch-action: none;
  display: block;
  box-sizing: border-box;
  border-radius: 50%;
  margin-top: 0;
  margin-inline-start: 0;
  background: colors.$primary;
  border-color: transparent;
  box-shadow: none;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  &::before {
    // IPad cannot display round outline
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background: transparent;
    border: none;
    border-radius: 50%;
  }

  &:focus:not(:focus-visible) {
    outline: none;
    &::before {
      border: 18px solid rgba(0, 124, 193, 0.45);
    }
  }

  &:hover:not(:not(:hover)) {
    &::before {
      border: 18px solid rgba(0, 124, 193, 0.25);
    }
  }

  &:active {
    height: 20px;
    width: 20px;
    outline: none;
    &::before {
      border: 18px solid rgba(0, 124, 193, 0.25);
    }
  }
}
