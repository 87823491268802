@use 'synergy/colors' as colors;

.p-float-label {
  display: inline-block;
  position: relative;
  > label {
    position: absolute;
    pointer-events: none;
    transition-property: all;
    transition-timing-function: ease;

    background-color: colors.$white;
    color: colors.$text-lighter;
    margin-left: 8px;
    white-space: nowrap;
    left: 10px;
    top: 50%;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    line-height: 1;
    margin-top: -0.5rem;
  }
}

.p-float-label {
  & > input:required ~ label::after,
  & > textarea:required ~ label::after,
  & > p-inputnumber.required + label::after,
  & > p-autocomplete.required + label::after {
    content: ' *';
  }
}

.p-float-label textarea ~ label {
  margin-top: 0;
  top: 1rem;
}

.p-float-label > input:placeholder-shown,
.p-float-label .p-inputnumber > input:placeholder-shown,
.p-float-label > textarea:placeholder-shown,
.p-float-label.always-expanded > * {
  &::placeholder,
  input::placeholder {
    color: colors.$text-lighter;
    opacity: 1;
  }

  &:disabled,
  input:disabled {
    &::placeholder,
    input::placeholder {
      color: colors.$text-disabled;
      opacity: 1;
    }
  }
}

.p-float-label > input:focus,
.p-float-label > input.p-filled,
.p-float-label > textarea:focus,
.p-float-label > textarea.p-filled,
.p-float-label > .p-inputwrapper-focus,
.p-float-label > .p-inputwrapper-filled,
.p-float-label > .p-inputtext:focus,
.p-float-label > input:placeholder-shown,
.p-float-label > p-inputnumber.placeholder,
.p-float-label > textarea:placeholder-shown,
.p-float-label.always-expanded > * {
  ~ label {
    top: 0;
    left: 0;
    font-size: 12px;
    padding: 0px 8px;
    margin-top: -0.5em;
  }
}

.p-float-label > input.p-filled,
.p-float-label > textarea.p-filled,
.p-float-label > .p-inputwrapper-filled,
.p-float-label > input:placeholder-shown,
.p-float-label > p-inputnumber.placeholder:not(.p-inputwrapper-focus, .disabled),
.p-float-label > textarea:placeholder-shown,
.p-float-label.always-expanded > * {
  ~ label {
    color: colors.$text;
  }
}

.p-float-label > input:focus,
.p-float-label > textarea:focus,
.p-float-label > .p-inputwrapper-focus,
.p-float-label > .p-inputtext:focus,
.p-float-label > p-autocomplete:focus {
  ~ label {
    color: colors.$primary;
  }
}

.p-float-label > input:disabled,
.p-float-label > p-inputnumber.disabled,
.p-float-label > textarea:disabled,
.p-float-label.disabled > * {
  ~ label {
    color: colors.$text-disabled;
  }
}
