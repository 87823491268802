@use 'synergy/colors' as colors;

p-fieldset {
  .p-fieldset {
    background-color: colors.$white;
    padding: 1em;

    border-style: solid !important;
    border-width: 1px !important;
    border-color: colors.$midgray-3 !important;
  }

  .p-fieldset-legend {
    background-color: colors.$lightgray-2;
  }
}
