@use 'synergy/colors' as colors;
@use 'synergy/tokens' as *;

p-select {
  position: relative;
  width: 100%;
  min-width: 0;
  height: 46px;
  display: flex;
  align-items: center;
  border: 1px solid colors.$input-border;
  border-radius: 0;
  background-color: colors.$background;

  &.p-focus {
    background-color: colors.$background;
  }

  &.p-select-open {
    .p-select-dropdown {
      transform: rotate(180deg);
    }
  }

  p-overlay {
    z-index: 9;
    > .p-overlay.p-component {
      max-width: 100%;
      /* !important is needed because the min-width is set automatically as inline style by primeng to a static value */
      min-width: 100% !important;
      margin-top: 0 !important;
    }
  }

  &:not(.p-disabled):hover {
    background-color: colors.$background;
    border: 1px solid colors.$input-border-hover;
  }

  &.p-inputwrapper-focus {
    outline: 2px solid colors.$focus-ring;
    border: 1px solid colors.$input-border-hover;
  }

  &.p-disabled {
    border-color: colors.$border-disabled;
    .p-select-label {
      color: colors.$text-disabled;
    }
    .p-select-dropdown {
      color: $SynColorNeutral500;
    }
  }

  .p-select-label {
    height: 35px;
    line-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 16px;
    border: none;

    display: flex;
    align-items: center;
    > * {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    span,
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      color: colors.$text;
    }
  }

  .p-select-dropdown {
    width: 40px;
    height: 40px;
    margin: 3px;
    color: colors.$text;
    border: none;
  }

  .p-dropdown-item-empty {
    text-indent: 0;
    padding: 0;
  }

  .p-placeholder {
    color: colors.$input-placeholder;
    opacity: 1;
  }

  .p-dropdown-filter-icon {
    visibility: none;
  }
}

.p-select-overlay {
  background: colors.$background;
  border: 1px solid colors.$panel-border;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px 0 $SynColorNeutral500;
  width: 100%;
  padding: 8px 0;
}

.p-select-list {
  padding: 0;
  p-selectitem {
    cursor: not-allowed;
  }
}

.p-select-option {
  min-height: 48px;
  margin: 0;
  padding: 12px 16px;
  color: colors.$text;
  background: transparent;

  display: flex;
  align-items: center;
  gap: 12px;

  .p-icon-wrapper {
    color: colors.$primary;
  }

  &.p-select-option-selected {
    background: colors.$primary;
    color: $SynColorNeutral0;
    .p-icon-wrapper {
      color: $SynColorNeutral0;
    }
  }

  &.p-disabled {
    color: colors.$text-disabled;
  }

  &:not(.p-select-option-selected):not(.p-disabled):hover {
    background: colors.$lightgray-3;
    color: $SynColorNeutral1000;
  }

  span,
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}
