@use 'synergy/colors' as colors;

p-table {
  .p-datatable-scrollable-header {
    overflow: visible; // to make drop-downs of multiselect work
  }
  .p-datatable {
    .p-datatable-header {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      padding: 9px;
      background-color: colors.$table-header-background;
    }
    table {
      color: colors.$table-content-color;
      width: 100%; // required for Primeng >12.1.1
      table-layout: fixed;

      .p-button {
        padding: 0;
        margin: 0 5px 0 0;
        height: auto;
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  // use styleClass="limit-height" in conjunction with [scrollable]="true"
  .p-datatable.limit-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    .p-datatable-wrapper {
      flex: 1 1 auto;
    }
  }

  &.ui-sticky-table-header > .p-datatable > .p-datatable-table-container {
    display: contents; // to conter overflow:auto
    .p-datatable-thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  thead,
  tfoot {
    font-weight: bold;
    background-color: colors.$table-header-background;
    & > tr {
      & > th {
        padding: 12px;
        border: none;
        background: colors.$table-header-background;
        font-weight: bold;
        color: colors.$table-content-color;
        vertical-align: baseline;
        // This is an improved ellipsis container for sortable column headers.
        // It makes sure that the direction icon is always visible.
        .ellipsis {
          display: flex;
          min-width: 0;
          align-items: center;
          & > label,
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        &.thin-header {
          padding: 0.2em 12px;
          .p-multiselect {
            height: auto;
            .p-multiselect-label-container {
              padding-left: 0.5em;
              .p-multiselect-label {
                padding-top: 0em;
                padding-bottom: 0em;
              }
            }
          }
        }
        &.p-datatable-sortable-column {
          label {
            cursor: pointer;
          }
          & > p-sorticon {
            flex-shrink: 0;
          }
        }
        .p-button {
          display: block;
          line-height: 0;
        }
      }
    }
  }
  tbody {
    & > tr {
      &:nth-child(even) {
        background-color: colors.$table-row-even;
      }
      &:nth-child(odd) {
        background-color: colors.$table-row-odd;
      }
      &:nth-child(odd):last-child {
        border-bottom: 1px solid colors.$table-row-even;
      }
      &.p-datatable-column-sorted {
        background-color: colors.$table-col-highlight;
      }
      & > td {
        padding: 12px;
        vertical-align: middle;
        overflow-wrap: break-word;
        border: inherit;
        color: colors.$table-content-color;
        .p-column-title {
          display: none;
        }
        .ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .flex-cell {
          display: flex;
          align-items: center;

          > * {
            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
        & > .expanded-row-container {
          padding: 0.5em 0 0.5em 0;
          width: 100%;

          > * .p-paginator {
            background: inherit;
          }

          .empty-table-message {
            padding-bottom: 0.5rem;
          }
        }
        .p-button {
          display: block;
          line-height: 0;
        }
      }

      .empty-table-message {
        padding: 0.5rem 0 0 0;
      }
    }
  }
  // loader
  .p-datatable-loading-overlay {
    background-color: rgba(0, 0, 0, 0.25);
  }

  &.narrow {
    .p-datatable {
      display: block;
      .p-datatable-thead,
      .p-datatable-tfoot {
        display: block;
        tr {
          display: flex;
          align-items: center;
          th {
            //:empty muss oben stehen, damit flex regeln nicht auf entfernte Elemente angewendet werden
            &:empty {
              display: none;
            }
            flex-grow: 1;
            flex-basis: 0px;
            width: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            label {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
      .p-datatable-tbody {
        display: grid;
        grid-template-columns: max-content auto;
        & > tr {
          display: contents;
          &:nth-child(even) > * > * {
            background-color: colors.$table-row-even;
          }
          &:nth-child(odd) > * > * {
            background-color: colors.$table-row-odd;
          }
          & > td {
            display: contents;
            & > div {
              // fill whole cell with background color
              align-self: stretch;
              // center content
              padding: 0.2em 0;
              // break long words when ellipsis is off
              word-break: break-word;
              text-align: initial;
            }
            .p-column-title {
              align-self: stretch;
              display: inline-block;
              padding: 0.2em 12px;
              font-weight: bold;
              white-space: nowrap;
            }
            & > .expanded-row-container {
              padding-right: 12px;
            }
          }
          //The content must be set, to enable the possibility to center the no results banner in table
          td.empty-table-message:before {
            content: '';
          }
          td.empty-table-message:after {
            content: '';
          }
        }
      }
    }
  }

  .p-datatable-scrollable .p-datatable-thead,
  .p-datatable-scrollable .p-datatable-tbody,
  .p-datatable-scrollable .p-datatable-tfoot {
    display: revert;
    > tr {
      display: revert;
      flex-wrap: revert;

      > th,
      td {
        display: revert;
        flex: revert;
        align-items: revert;
      }
    }
  }
}
