@use 'synergy/colors' as colors;

p-toast {
  .p-toast .p-toast-message-content {
    color: colors.$darkgray-1;
    padding: 0;
    p-button {
      display: none;
    }
  }

  .p-toast-top-right {
    top: 80px;
  }
}
