@use 'primeng/primeng-theme' as *;
@use './_checkbox' as *;
@use 'synergy/colors' as colors;

p-multiselect {
  &.abbrev-drop-down {
    .p-multiselect-overlay {
      width: 100%;
      max-width: fit-content;
      z-index: 100;
    }
    .p-multiselect-option {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  height: 46px;
  align-items: center;
  width: 100%;
  border: thin solid colors.$midgray-1;
  color: colors.$darkgray-1;
  border-radius: 0;
  background-color: colors.$white;
  box-shadow: none;

  .p-multiselected-item-token.p-corner-all.ng-star-inserted {
    background: colors.$lightgray-2;
    display: inline-block;
    margin: 2px;
    padding: 2px;
    color: colors.$darkgray-2 img {
      margin-right: 4px;
    }

    .pi-caret-down:before {
      color: colors.$primary;
    }
  }

  .p-multiselect-label {
    background-color: #ffffff;
    white-space: nowrap;
  }

  .p-multiselect-label.p-multiselect-label-empty {
    visibility: visible;
  }

  &.p-disabled {
    color: colors.$text-disabled;
    border-color: colors.$outline-light;
    .p-multiselect-dropdown {
      color: colors.$outline-light;
    }
  }

  .p-multiselect-dropdown {
    color: colors.$darkgray-4;
    margin-right: 1em;
  }

  .p-multiselect-label-container {
    text-align: left;
    padding-left: 1em;
    background-color: colors.$white;
  }

  &.p-focus {
    background-color: colors.$white;
    color: colors.$midgray-1;
    border-color: colors.$midgray-1;
  }
}

.p-overlay {
  .p-multiselect-overlay {
    overflow-x: hidden !important;
    z-index: 100;
    background-color: colors.$white;
    border: thin solid;
    margin-top: 2px;
    border-color: colors.$midgray-1;
    box-shadow: none;
    border-radius: 0;

    .p-multiselect-header {
      margin: 6px;
      padding: 0;
      div.p-checkbox {
        display: none;
      }
      .p-multiselect-filter-container {
        input.p-multiselect-filter.p-inputtext {
          padding-left: 48px;
        }
        .p-inputicon {
          position: absolute;
          inset-inline-end: auto;
          left: 23px;
          top: 12px;
          margin-top: 0;
          color: colors.$darkgray-1;
        }
      }

      button.p-multiselect-close {
        display: none;
      }
    }

    .p-multiselect-list {
      padding: 0;
      display: block;
      flex-direction: unset;
      gap: unset;
    }

    .multiselect-footer {
      margin: 6px 0;
    }

    .p-multiselect-close {
      color: $headerIconTextColor;
    }

    .p-multiselect-filter-container .fa {
      color: $inputTextColor;
    }
  }
}

.p-multiselect-empty-message {
  padding: 6px 16px;
}
