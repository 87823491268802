@use 'sds/fonts' as *;
@use 'primeng/components/button' as *;
@use 'synergy/colors' as colors;

.p-tabs {
  .p-tablist {
    background: colors.$background;
    .p-tablist-content {
      height: 48px;
      border-bottom: 1px solid colors.$outline-light;
      .p-tablist-tab-list {
        background: transparent;
      }
      .p-tab {
        height: 48px;
        margin: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        padding: 0 24px;
        font-weight: $font-semibold;
        @include button-bare(':disabled', transparent, colors.$text);
        &.p-tab-active {
          background: colors.$background;
          border-bottom: 4px solid colors.$primary;
        }
      }
      .p-tablist-active-bar {
        display: none;
      }
    }
    .p-tablist-nav-button {
      background: colors.$background;
    }
  }
  .p-tabpanels {
    overflow: hidden;
    padding: 0;
    .p-tabs-panel {
      height: auto;
      > * {
        height: inherit;
      }
    }
  }
}
