@use 'primeng/components/button' as *;
@use 'synergy/colors' as colors;

.p-dialog {
  box-shadow: 1px 2px 5px colors.$midgray-2;
  width: 100%;
  max-width: 38em;

  .p-dialog-header {
    color: colors.$darkgray-2;
    background: colors.$white;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    padding: 24px 24px 16px;

    .p-dialog-title {
      margin-right: 8px;
      font-weight: inherit;
      font-size: inherit;
    }

    .p-button {
      padding: 0;
      min-height: 0;
      height: 24px;
      @include button-bare-neutral;
    }
  }

  .p-dialog-content {
    padding: 0em 24px 24px;
    background: colors.$white;
    display: grid;
    color: colors.$darkgray-1;
    flex: 1 1 auto;
  }

  .p-dialog-footer {
    border: 0 none;
    padding: 0;
    background: colors.$white;

    p-footer {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 24px 24px;

      button {
        min-width: 140px;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}

.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.4);
  & > .p-dialog {
    box-shadow: none;
  }
}
