@use 'synergy/colors' as colors;

p-inputnumber {
  border: solid 1px colors.$midgray-1;

  &.p-inputwrapper-focus {
    outline: none;
    border-color: colors.$primary;
  }
  &.disabled {
    color: colors.$text-disabled;
    border-color: colors.$midgray-3;
  }

  .p-inputnumber-input.p-inputtext {
    // disable inherited border
    border: none;
  }

  .p-inputnumber-button-group .p-inputnumber-button {
    margin: 0;
    padding: 0;
    width: min-content;
    border-radius: 0;
    height: auto;
    border-left: solid 1px colors.$midgray-1;
    color: colors.$darkgray-2;

    &:not(:last-child) {
      border-bottom: solid 1px colors.$midgray-1;
    }

    &:disabled {
      border-color: colors.$midgray-3;
    }
  }
}
