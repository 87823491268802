@use 'synergy/colors' as colors;

.p-splitter-gutter {
  background-color: colors.$midgray-2;
  &:active {
    background-color: colors.$darkgray-2;
  }
}
.p-splitter-horizontal {
  height: 100%;
}
.p-splitter-vertical {
  height: 100%;
}
