@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url(../fonts/open-sans-v15-latin-regular.woff2) format('woff2'),
    url(../fonts/open-sans-v15-latin-regular.woff) format('woff'),
    url(../fonts/open-sans-v15-latin-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url(../fonts/open-sans-v15-latin-600.woff2) format('woff2'),
    url(../fonts/open-sans-v15-latin-600.woff) format('woff'),
    url(../fonts/open-sans-v15-latin-600.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url(../fonts/open-sans-v15-latin-700.woff2) format('woff2'),
    url(../fonts/open-sans-v15-latin-700.woff) format('woff'),
    url(../fonts/open-sans-v15-latin-700.ttf) format('truetype');
}

h1 {
  font-size: 34.84px;
  line-height: 1.2;
  font-weight: 400;
}
h2 {
  font-size: 29.03px;
  line-height: 1.2;
  font-weight: 400;
}
h3 {
  font-size: 24.19px;
  line-height: 1.3;
  font-weight: 400;
}
h4 {
  font-size: 20.16px;
  line-height: 1.3;
  font-weight: 600;
}
h5 {
  font-size: 16.8px;
  line-height: 1.4;
  font-weight: 700;
}
h6 {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 700;
}
p {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
}
p.s {
  font-size: 11px;
  line-height: 1.4;
  font-weight: 400;
}
p.xs {
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
}
