@use 'synergy/colors' as colors;

.p-inputtext {
  border: solid 1px colors.$midgray-1;
  border-radius: 0;
  color: colors.$text;
  padding: 12px 16px;
  text-indent: 0;
  height: 46px;
  overflow: auto;
  box-shadow: none;

  &:focus {
    outline: none;
    border-color: colors.$primary;
  }

  &:disabled {
    color: colors.$text-disabled;
    border-color: colors.$midgray-3;
  }

  // See https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px colors.$white inset;
    -webkit-text-fill-color: colors.$text;
  }
}
