@use 'synergy/colors' as colors;

.p-tooltip.p-tooltip-right,
.p-tooltip.p-tooltip-left,
.p-tooltip.p-tooltip-top,
.p-tooltip.p-tooltip-bottom {
  padding: 0;
}
// only works for right (used if enough space)
.p-tooltip.p-tooltip-right {
  margin-left: 8px;
}
.p-tooltip {
  border: 1px solid colors.$midgray-1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.p-tooltip:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: white;
  transform: rotate(45deg);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.p-tooltip.p-tooltip-right:before {
  bottom: calc(50% - 4px);
  left: -4px;
  border-left: 1px solid colors.$midgray-1;
  border-bottom: 1px solid colors.$midgray-1;
}
.p-tooltip.p-tooltip-left:before {
  bottom: calc(50% - 4px);
  right: -4px;
  border-right: 1px solid colors.$midgray-1;
  border-top: 1px solid colors.$midgray-1;
}
.p-tooltip.p-tooltip-bottom:before {
  left: calc(50% - 4px);
  top: -4px;
  border-left: 1px solid colors.$midgray-1;
  border-top: 1px solid colors.$midgray-1;
}
.p-tooltip.p-tooltip-top:before {
  left: calc(50% - 4px);
  bottom: -4px;
  border-right: 1px solid colors.$midgray-1;
  border-bottom: 1px solid colors.$midgray-1;
}
.p-tooltip .p-tooltip-text {
  background-color: white;
  color: colors.$darkgray-1;
  padding: 16px;
}
.p-tooltip .p-tooltip-text.p-shadow {
  box-shadow: none;
}
// disable the standard arrow
.p-tooltip-right .p-tooltip-arrow {
  border: none;
}
.p-tooltip-left .p-tooltip-arrow {
  border: none;
}
.p-tooltip-top .p-tooltip-arrow {
  border: none;
}
.p-tooltip-bottom .p-tooltip-arrow {
  border: none;
}
