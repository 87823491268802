@use 'synergy/colors' as colors;

.p-textarea {
  resize: none;
  height: auto;
  background: transparent;
  color: colors.$text;

  border: solid 1px colors.$midgray-1;
  padding: 12px 16px;
  text-indent: 0;
  overflow: auto;
  border-radius: 0;

  &:disabled {
    color: colors.$text-disabled;
    -webkit-text-fill-color: colors.$text-disabled;
    opacity: 1;
  }

  &:enabled:focus {
    border-color: colors.$primary;
    box-shadow: none;
    outline-offset: none;
    outline: none;
  }
}

.p-textarea::-webkit-scrollbar {
  width: 18px;
  background: colors.$white;
}
.p-textarea::-webkit-scrollbar-thumb {
  border: 6px solid colors.$white;
  border-radius: 10px;
  background: colors.$midgray-2;
}
.p-textarea::-webkit-scrollbar-thumb:hover {
  background: colors.$darkgray-4;
}
// Scrollbar workaround for Firefox
.p-textarea {
  scrollbar-color: colors.$midgray-2 colors.$white;
}
