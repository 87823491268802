@use 'sds/fonts' as *;
@use 'primeng/primeng-theme' as *;
@use 'primeng/components/button' as *;
@use 'synergy/colors' as colors;

p-datepicker {
  .p-datepicker {
    width: 100%;
  }
  .p-datepicker-panel {
    color: colors.$text;
    border: none;
  }
  .p-datepicker-day-cell {
    padding: 0;
  }
  .p-datepicker-day {
    padding: 0.5em;
    color: colors.$text;
    height: auto;
    width: auto;
    border: none;
    &:not(.p-disabled):hover {
      color: inherit;
      background: colors.$table-header-background;
    }
    &.p-datepicker-day-selected,
    &.p-datepicker-day-selected-range {
      @include button-primary;
    }
    &.p-disabled {
      color: colors.$text-disabled;
    }
  }
  .p-datepicker-today > span {
    border: 1px solid colors.$midgray-3;
    background: inherit;
    color: inherit;
  }
  .p-datepicker-month {
    padding: 0;
    color: colors.$text;
    &:hover {
      color: inherit;
      background: colors.$table-header-background;
    }
    &.p-datepicker-month-selected {
      background-color: colors.$primary;
      color: colors.$white;
      &:hover {
        background-color: colors.$primary-light;
        color: colors.$white;
      }
      &:active {
        background-color: colors.$primary-dark;
        color: colors.$white;
      }
    }
  }
  .p-datepicker-year {
    padding: 0;
    color: colors.$text;
    &:hover {
      color: inherit;
      background: colors.$table-header-background;
    }
    &.p-datepicker-year-selected {
      background-color: colors.$primary;
      color: colors.$white;
      &:hover {
        background-color: colors.$primary-light;
        color: colors.$white;
      }
      &:active {
        background-color: colors.$primary-dark;
        color: colors.$white;
      }
    }
  }
  .p-datepicker .p-datepicker-header {
    padding: 0.5em 0;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title select {
    margin: 0.125em 0;
    vertical-align: middle;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.25em;
  }

  .p-datepicker.p-component .p-datepicker-calendar td .p-disabled {
    color: colors.$text-disabled;
  }

  .p-datepicker-weekheader {
    opacity: 1;
  }
}

/* previous style - deprecated - for compatibility only */
.p-datepicker.p-component {
  .p-datepicker-header {
    border: none;
    p-button {
      display: contents;
    }
    button {
      padding: 0;
      height: auto;
      gap: 0;
      @include button-bare-neutral;
    }
  }

  .p-datepicker-day-view {
    margin: 0;

    th {
      width: 3em;
      background-color: colors.$midgray-3;
      text-align: center;
      padding: 0.5em;
      .p-datepicker-weekday {
        color: colors.$text-light;
        font-weight: bold;
      }
    }

    td {
      border-bottom: 1px solid rgba(213, 213, 213, 0.5);
    }

    tr:last-child td {
      border-bottom: 0 none;
    }
  }

  // date picker styles for inline mode
  .p-datepicker-panel-inline {
    box-shadow: none;
    padding: 0;
    border: none;
    .p-datepicker-header {
      padding-top: 0;
    }

    .p-datepicker-calendar {
      td {
        border: none;
        &.p-datepicker-weeknumber {
          color: colors.$midgray-3;
          font-weight: bold;
          border-right: 1px solid colors.$midgray-3;
        }
      }
    }
  }

  .menu-canvas &.p-datepicker-inline {
    // The primeNG calendar sets the width in the ngAfterViewInit hook which causes a width of 0px.
    // Unfortunately this is only called once and can not be updated, so the width of 0px remains
    // As a workaround overwrite the p-datepicker-inline inline width of 0px
    width: 100% !important;
  }
}

/* DatePicker trying to be close to SDS */
p-datepicker {
  .p-datepicker-dropdown {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    @include button-bare;
    width: auto;
    height: 100%;
    padding: 0 16px;
    border: none;
  }
}

@mixin calendar-yearpicker-sds {
  user-select: none;
  display: inline-grid;
  grid-template-columns: 56px 56px;
  gap: 2px 16px;
  .p-datepicker-year {
    width: 56px;
    height: 36px;
    border-radius: 18px;
    &:hover {
      background-color: colors.$primary;
      color: colors.$white;
      font-weight: $font-semibold;
    }
    &:active {
      background-color: colors.$primary-dark;
      color: colors.$white;
      font-weight: $font-semibold;
    }
    &.p-datepicker-year-selected {
      background-color: colors.$primary;
      color: colors.$white;
      font-weight: $font-semibold;
      &:hover {
        background-color: colors.$primary-light;
        color: colors.$white;
      }
      &:active {
        background-color: colors.$primary-dark;
        color: colors.$white;
      }
    }
  }
}

@mixin calendar-monthpicker-sds {
  user-select: none;
  display: inline-grid;
  grid-template-columns: 56px 56px 56px;
  gap: 2px 16px;
  .p-datepicker-month {
    width: 56px;
    height: 36px;
    border-radius: 18px;
    &:hover {
      background-color: colors.$primary;
      color: colors.$white;
      font-weight: $font-semibold;
    }
    &:active {
      background-color: colors.$primary-dark;
      color: colors.$white;
      font-weight: $font-semibold;
    }
    &.p-datepicker-month-selected {
      background-color: colors.$primary;
      color: colors.$white;
      font-weight: $font-semibold;
      &:hover {
        background-color: colors.$primary-light;
        color: colors.$white;
      }
      &:active {
        background-color: colors.$primary-dark;
        color: colors.$white;
      }
    }
  }
}

.p-datepicker-panel.sds {
  background-color: colors.$white;
  box-shadow: 1px 2px 5px colors.$midgray-2;
  text-align: center;
  padding: 0;

  .p-datepicker-header {
    display: block;
    height: 56px;
    background-color: colors.$primary;
    color: colors.$white;
    position: relative;
    min-width: 250px;
    .p-datepicker-prev-button {
      position: absolute;
      top: 16px;
      right: 72px;
      chevronlefticon {
        color: colors.$white;
      }
    }
    .p-datepicker-next-button {
      position: absolute;
      top: 16px;
      right: 24px;
      chevronrighticon {
        color: colors.$white;
      }
    }
    .p-datepicker-title {
      position: absolute;
      top: 16px;
      left: 24px;
      font-size: 17px;
      button:hover {
        background-color: colors.$primary;
        color: colors.$white;
      }
      .p-datepicker-select-month {
        color: colors.$white;
        margin-right: 0.25em;
      }
      .p-datepicker-select-year {
        color: colors.$white;
      }
    }
  }
  .p-datepicker-calendar-container {
    padding: 0;
    font-size: $font-size-default;
    .p-datepicker-calendar {
      th {
        padding: 4px;
        font-weight: $font-semibold;
        color: colors.$text-lighter;
        background-color: colors.$white;
        width: auto;
        & > * {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      td {
        padding: 4px;
        border: none;
        &.p-datepicker-other-month {
          color: colors.$text-disabled;
        }
        &.p-datepicker-weeknumber {
          font-weight: $font-semibold;
          color: colors.$text-lighter;
        }
        & > * {
          width: 36px;
          height: 36px;
          border-radius: 18px;
          margin-left: auto;
          margin-right: auto;

          &.p-datepicker-day-selected {
            background-color: colors.$primary;
            color: colors.$white;
            font-weight: $font-semibold;
          }
          &:hover {
            background-color: colors.$primary-light;
            color: colors.$white;
            font-weight: $font-semibold;
          }
        }
      }
    }
  }
  .p-datepicker-day-view {
    padding: 16px 12px;
    border-collapse: separate;
  }
  .p-datepicker-month-view {
    margin: 12px 0;
    @include calendar-monthpicker-sds;
  }
  .p-datepicker-year-view {
    margin: 12px 0;
    @include calendar-yearpicker-sds;
  }
}
