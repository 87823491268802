@use 'synergy/colors' as colors;

p-chip {
  background-color: colors.$info-light;
  padding: 0.25rem 1rem 0.25rem 1rem;
  margin-right: 1rem;
  border-radius: 1.2rem;
  color: colors.$darkgray-2;
  font-size: 14px;
  gap: 0.25rem;

  .p-chip-remove-icon {
    font-size: 18px;
    height: auto;
    color: colors.$darkgray-2;
  }

  &.searchValue {
    background-color: colors.$search-value;
  }

  &.selectedTypes {
    background-color: colors.$selected-types;
  }

  &.selectedVendors {
    background-color: colors.$selected-vendors;
  }

  &.selectedTrees {
    background-color: colors.$selected-trees;
  }

  &.selectedBuildingsOrLocations {
    background-color: colors.$selected-buildings-or-locations;
  }

  &.selectedProductNames {
    background-color: colors.$selected-product-names;
  }

  &.selectedFirmwareStates {
    background-color: colors.$selected-firmware-states;
  }
}
