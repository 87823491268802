@use 'synergy/colors' as colors;

.p-progressbar {
  height: 0.5em;
  background: colors.$midgray-3;
}

.p-progressbar.p-progressbar-determinate {
  height: 1.5em;
  .p-progressbar-label {
    font-size: 9pt;
  }
}
