@use 'synergy/colors' as colors;

.p-tree {
  padding: 0;
  .p-tree-root-children {
    overflow: hidden;
  }

  .p-tree-node-toggle-button {
    color: colors.$primary;
    font-size: 24px;
    width: 1.28571429em;
    border-radius: 0;
    &:hover {
      background: unset;
    }
  }

  .p-tree-node {
    .p-tree-node-selected {
      background-color: inherit;
      border-color: inherit;
      color: inherit;

      .p-tree-node-label {
        color: white;
        background-color: colors.$primary-light;
      }
    }

    .p-tree-node-content {
      padding: 0;
      gap: 0;
      .p-tree-node-label {
        color: colors.$text-disabled;
        border-radius: 0.2em;
        overflow: hidden;
      }
      &.p-tree-node-selectable {
        .p-tree-node-label {
          color: colors.$text;
        }
      }
    }

    .p-tree-node-selectable:not(.p-tree-node-selected):hover {
      background: unset;
      color: unset;
      .p-tree-node-label {
        background: colors.$primary-200;
        color: colors.$text;
      }
    }

    ul.p-tree-node-children {
      margin-left: 1rem;
      padding: 0;
    }

    .p-tree-node-label {
      padding: 1px 4px 1px 4px;
      white-space: nowrap;
    }

    .p-tree-node-droppoint {
      height: 2px;
    }

    .p-tree-node-dragover {
      background-color: colors.$midgray-3;
    }

    .p-tree-node-droppoint-active {
      background-color: colors.$midgray-3;
      height: 24px;
    }
  }
}
